import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";
import TrackerManager from './TrackerManager';
import {GoogleProcessor} from "./processors/GoogleProcessor";
import {MatomoProcessor} from "./processors/MatomoProcessor";

export class TrackerComponent extends PewComponent {

    init()
    {
        this.debug = (window.wonderwp && window.wonderwp.trackers && window.wonderwp.trackers.opts && window.wonderwp.trackers.opts.debug) ? window.wonderwp.trackers.opts.debug : false;
        this.EventManager = window.EventManager || $(document);
        if (this.element.hasClass('has-form')) {
            this.registerFormEvents();
        }
        if (this.element.hasClass('has-reopener')) {
            this.registerManagerReopener();
        }
        let cacheEnabled = this.element.data('cache-enabled');
        if (cacheEnabled) {
            const cookieSet = this.checkIfCookieSet();
            if (cookieSet) {
                this.asyncLoadTrackers();
            } else {
                this.EventManager.trigger('wwp-cache-onCacheNeedsRefresh', {});
                this.asyncLoadFormFromCache();
            }
        }
        this.initTrackerManager();
        this.registerCustomTrackers();
    }

    registerFormEvents()
    {
        this.registerDeny();
        this.registerMoreOptionsToggler();
        this.registerFormSubmit();
    }

    registerMoreOptionsToggler()
    {
        this.element.find('.btn-more-options').on('click', (e) => {
            e.preventDefault();
            this.element.find('.checkbox').prop('checked', false);
            this.element.find('.trackers-list').slideDown(400, () => {
                const $submitBtn = this.element.find('button[type="submit"]');
                $submitBtn.text($submitBtn.data('alt-submit-text'));
                this.element.find('.submitFormField').toggleClass('choices-active')
                $(e.currentTarget).fadeOut();
            });
        });
    }

    registerFormSubmit()
    {
        this.element.find('form').on('submit', (e) => {
            e.preventDefault();
            this.submitForm($(e.currentTarget));
        });
    }

    submitForm($form)
    {
        this.removeNotifications();

        const formData = $form.serializeArray();

        $form.addClass('loading').find('input,button').prop('disabled', true);
        $.post($form.attr('action'), formData, (res) => {
            var type = '';
            if (res && res.code && res.code === 200) {
                this.onSuccess(res, $form);
                type = 'success';
            } else {
                this.onError(res, $form);
                type = 'error';
            }
            this.triggerEvents(res, $form, type);
            $form.removeClass('loading').find('input,button').prop('disabled', false);
        });
    }

    onSuccess(res, $form)
    {
        this.notify('success', res.data.msg, this.element);
        this.insertTrackers(res.data.enabledTrackingCode);

        if (!$('body').hasClass('page-vos-donnees-personnelles')) {
            this.element.find('.trackers-cookie-container').slideUp();
            setTimeout(() => {
                if (this.element.hasClass('has-reopener')) {
                    this.showReopener();
                } else {
                    this.element.fadeOut(400, () => {
                        this.element.remove();
                    });
                }
            }, 2000);
        }

    }

    onError(res, $form)
    {
        this.notify('error', res.data.msg || 'Error', this.element);
    }

    notify(type, msg, $context)
    {
        this.EventManager.trigger('notification', {
            type: type,
            msg: msg,
            dest: $context,
            focus: false
        });
    }

    registerDeny()
    {
        this.element.find('.btn-deny').on('click', (e) => {
            e.preventDefault();
            this.element.find('.checkbox').prop('checked', false);
            this.submitForm(this.element.find('form'));
        });
    }

    insertTrackers(enabledTrackingCodes)
    {
        this.log('Inserting trackers');
        this.log(enabledTrackingCodes);
        let $head = $('head');
        let trackingCodeMarkup = "<!-- [wwp-tracker] -->\n";
        for (let i in enabledTrackingCodes) {
            trackingCodeMarkup += this.stripslashes(enabledTrackingCodes[i]);
        }
        trackingCodeMarkup += '<!-- [/wwp-tracker] -->';
        if ($head.length) {
            $head.append(trackingCodeMarkup);
        }
    }

    stripslashes(str)
    {
        return (str + '').replace(/\\(.?)/g, function (s, n1) {
            switch (n1) {
                case '\\':
                return '\\';
                case '0':
                return '\u0000';
                case '':
                return '';
                default:
                return n1;
            }
        });
    }

    removeNotifications()
    {
        const $alert = this.element.find('.alert');
        if ($alert.length) {
            $alert.remove();
        }
    }

    checkIfCookieSet()
    {
        const cookieVal = this.getCookieVal('enabled_trackers');

        return (cookieVal && cookieVal.length > 0);
    }

    getCookieVal(cname)
    {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    asyncLoadTrackers()
    {
        this.log('listen for onCacheLoadSuccess (trackers)');
        this.EventManager.on('wwp-cache-onCacheLoadSuccess', (event, eventData) => {
            this.log('on CacheLoadSuccess : loading trackers');
            const res = eventData.res;
            if (res && res.data && res.data['wwp-tracker'] && res.data['wwp-tracker']['trackers']) {
                let trackersToInsert = [];
                for (let i in res.data['wwp-tracker']['trackers']) {
                    let trackerData = res.data['wwp-tracker']['trackers'][i];
                    if (TrackerComponent.isTrackerAllowedInPage(trackerData, window.location.href, window.location.protocol + '//' + window.location.host)) {
                        this.log('Tracker is allowed in page', trackerData, window.location.href);
                        trackersToInsert.push(trackerData.code);
                    }
                }
                if (trackersToInsert.length) {
                    this.insertTrackers(trackersToInsert);
                }
            }
        });
    }

    static isTrackerAllowedInPage(trackerData, url, siteUrl)
    {
        if (trackerData && trackerData.urls && trackerData.urls.length > 0) {
            const allowedUrls = trackerData.urls;
            for (let j in allowedUrls) {
                let pattern = allowedUrls[j];
                pattern = ''
                + pattern.trim()
                .replace(siteUrl, '')
                .replace('/', '\/')
                + '';

                let regex = new RegExp(pattern),
                isAllowed = regex.test(url);

                if (isAllowed) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    }

    asyncLoadFormFromCache()
    {
        this.log('listen for onCacheLoadSuccess (form)');
        this.EventManager.on('wwp-cache-onCacheLoadSuccess', (event, eventData) => {
            const res = eventData.res;
            if (res.data['wwp-tracker'] && res.data['wwp-tracker']['form']) {
                this.log('on CacheLoadSuccess : loading form');
                this.injectForm(res.data['wwp-tracker']['form']);
            }
        });
    }

    injectForm(formMarkup)
    {
        formMarkup = this.stripslashes(formMarkup);
        const innerContent = $(formMarkup).html();
        this.element.html(innerContent);
        this.element.removeClass('no-form').addClass('has-form');
        this.registerFormEvents();
        this.registerManagerReopener();
    }

    log(msg)
    {
        if (this.debug) {
            console.log('[wwp-tracker] ', ...arguments);
        }
    }

    initTrackerManager()
    {
        this.trackerManager = new TrackerManager({
            google: GoogleProcessor,
            matomo: MatomoProcessor
        });
    }

    registerCustomTrackers()
    {
        this.registerPageViewHandler();
        this.registerCustomEventHandler();
    }

    registerManagerReopener()
    {
        this.element.find('.manager-reopener').on('click', () => {
            const $formWrap = this.element.find('.trackers-cookie-container');
            if ($formWrap.length) {
                //the form is already here
                this.element.find('.manager-reopener').fadeOut(300, () => {
                    this.element.addClass('has-form');
                    $formWrap.fadeIn();
                });
            } else {
              //the form is not there
                this.element.addClass('loading').find('input,button').prop('disabled', true);
                $.get('/trackers/get_form', {}, (res) => {
                    if (res && res.code && res.code === 200) {
                        this.element.removeClass('loading');
                        this.injectForm(res.data['content']);
                    }
                });
            }
        });
    }

    showReopener()
    {
        const $alert = this.element.find('.alert');
        $alert.slideUp(400, () => {
            this.element.find('.manager-reopener').fadeIn(300, () => {
                this.element.removeClass('has-form');
            });
        $alert.remove();
        });
    }

    registerPageViewHandler()
    {
        this.EventManager.on('Tracking.pageEvent', (e, data) => {
            this.trackerManager.emit('Tracking.pageEvent', data);
        });
    }

    registerCustomEventHandler()
    {
        this.EventManager.on('Tracking.customEvent', (e, data) => {
            this.trackerManager.emit('Tracking.customEvent', data);
        });
    }

    triggerEvents(submitRes, $form, type)
    {
      //Trigger submit event for others
        EventManager.trigger('trackers.submit.' + type, {
            $form: $form,
            res: submitRes,
        });

      //Record submit event in analytics
        EventManager.trigger('Tracking.customEvent', {
            action: 'form_submit',
            category: 'trackers',
            label: 'Fermeture page : ' + window.location.pathname,
        });

      //Trigger cache invalidation
        this.EventManager.trigger('wwp-cache-onCacheNeedsRefresh', {});
    }

}

if (window.pew) {
    window.pew.addRegistryEntry({key: 'wdf-tracker', domSelector: '.module-tracker', classDef: TrackerComponent});
}
